import Axios from "axios";
import token from "./setToken";

export default {
  async getAnswerData(surveyId, memberNo, branch) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/ElectricityAnswer/" + surveyId + "/" + memberNo + "/" + branch, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査回答を取得できません[getOfficeData]");
      throw error;
    }
  },

  async setData(surveyId, memberNo, branch, contactData, otherNames, main10ElecCo, newElecCo, selfGeneration, qaList) {
    let params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("memberNo", memberNo);
    params.append("branch", branch);
    params.append("contactData", JSON.stringify(contactData));
    params.append("otherNames", JSON.stringify(otherNames));
    params.append("main10ElecCo", JSON.stringify(main10ElecCo));
    params.append("newElecCo", JSON.stringify(newElecCo));
    params.append("selfGeneration", JSON.stringify(selfGeneration));
    params.append("qaList", JSON.stringify(qaList));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityAnswer", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査回答を設定できません[setData]");
      throw error;
    }
  },

  async setBranch(item) {
    let params = new URLSearchParams();
    params.append("item", JSON.stringify(item));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityAnswerBranch", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査回答区分を設定できません[setBranch]");
      throw error;
    }
  },

  async setStatus(surveyId, memberNo, branch, contactData) {
    let params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("memberNo", memberNo);
    params.append("branch", branch);
    params.append("contactData", JSON.stringify(contactData));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityAnswerStatus", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("ステータスを保存できません[setStatus]");
      throw error;
    }
  },

  async delBranch(surveyId, memberNo, branch) {
    try {
      // await token.checkToken();
      const res = await Axios.delete("/api/ElectricityAnswerBranchDelete/"+surveyId+"/"+memberNo+"/"+branch, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査回答区分を削除できません[delBranch]");
      throw error;
    }
  },
};
