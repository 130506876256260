import Axios from "axios";
import token from "./setToken";

export default {
  async setAnsPeriod(dest) {
    let params = new URLSearchParams();
    params.append("dest", JSON.stringify(dest));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricitySetAnsPeriod", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("回答受付期間を保存できません[setAnsPeriod]");
      throw error;
    }
  },
};
