<template>
  <v-snackbar
    class="mt-16"
    text
    :color="color"
    v-model="snackbar"
    top
    timeout="-1"
  >
    {{message}}
    <template v-if="close" v-slot:action="{ attrs }">
      <v-icon v-bind="attrs" @click="snackbar = false">mdi-close-circle</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    disp: {
      type: Boolean,
      default: false,
    },
    message: String,
    close: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      snackbar: false,
    };
  },

  watch: {
    disp: {
      handler: function (value) {
        this.snackbar = value;
      },
    },
  },

  methods: {
  },

  computed: {
  },
};
</script>

<style scoped>
</style>