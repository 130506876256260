import * as XLSX from "xlsx";

export default {
  methods: {
    
    getEncode_cell(cv, rv) {
      return XLSX.utils.encode_cell({c:cv, r:rv});
    },

    getCellValue(sheet, cell, field, isNumberChk=false) {
      let v = null;

      const cellObj = sheet[cell];

      if(typeof cellObj !== "undefined" && cellObj !== null) {
        if(field == 'v' && typeof cellObj.v !== "undefined" && cellObj.v !== '') {
          if(!isNumberChk) {
            v = cellObj.v;
          } else {
            if(typeof cellObj.v !== 'number') {
              v = null;
            } else {
              v = cellObj.v;
            }
          }
        } else if(field == 'w' && typeof cellObj.w !== "undefined" && cellObj.w != '') {
          v = cellObj.w;
        }
      }

      return v;
    },

  },
};
